<template>
    <div v-if="mayIsee">
      <b-container>
        <b-row>
          <b-col>
              <b-row>
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Buscar:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="name"
                                  maxlength="100"
                                  @keyup.enter.native="search"
                                  v-model="form.search"
                                  placeholder="Informe o CNPJ da matriz ou filial da empresa">
                      </b-form-input>
                      <b-form-checkbox id="showEmpresasInativas" style="margin-left: 15px;"
                          v-model="showEmpresasInativas">
                        <span>Exibir empresas inativas</span>
                      </b-form-checkbox>
                      <b-form-checkbox id="filterFilial" style="margin-left: 15px;"
                          v-model="filterFilial">
                        <span>Filtrar apenas filiais</span>
                      </b-form-checkbox>
                      <b-button type="button" variant="primary" size="sm" @click="search">
                        <v-wait for="inprocess">
                            <template slot="waiting">
                                Consultando...
                            </template>
                        </v-wait>
                        <span v-if="!processing">Consultar</span>
                      </b-button>
                  </b-input-group>
              </b-row>
              <b-row v-if="grids.corporates.items.length > 0" class="mt-3">
                <b-input-group size="sm">
                    <h6>Total de Registros: {{ grids.corporates.items[0].totalCount  }}</h6>
                </b-input-group>
              </b-row>
          </b-col>

        </b-row>
        <b-row>
          <b-table striped="striped"
                  outlined="outlined"
                  class="fontSize tableClicked bg-white"
                  small="small"
                  hover="hover"
                  responsive
                  show-empty
                  empty-text="Não foram encontrados registros."
                  :per-page="grids.corporates.perPage"
                  v-if="grids.corporates.loaded"
                  :items="grids.corporates.items"
                  :fields="grids.corporates.fields">

              <template slot="actions" slot-scope="data">
                  <span v-if="!mayI('corporate-add')">-</span>
                  <b-button-group size="sm" v-if="mayI('corporate-add')">
                      <b-button title="Editar" v-if="mayI('corporate-add')" @click.stop="edit(data.item,$event.target)">
                        <span v-if="!processing">
                          Editar
                        </span>
                        <v-wait for="inprocess">
                            <template slot="waiting">
                                Carregando...
                            </template>
                        </v-wait>
                      </b-button>
                  </b-button-group>
              </template>
          </b-table>
          <b-pagination @change="pagto" size="sm" v-if="grids.corporates.loaded && grids.corporates.total>grids.corporates.perPage" :total-rows="grids.corporates.total" v-model="grids.corporates.currentPage" :per-page="grids.corporates.perPage">
          </b-pagination>
        </b-row>
      </b-container>
    </div>
</template>

<script>
import Vue from "vue";
import VueHead from 'vue-head';
import config from "@/config";
import { func } from "@/functions";
import { corporateService } from '../../components/common/services/corporate';

Vue.use(VueHead);

export default {
  mixins: [func],
  name: 'corporate-list',
  head: {
    title: function () {
      return { 
        inner: `TicketOffice | Admin`,
        separator: " | ",
        complement: "Usuário - Lista",
      }
    },
  },
  computed: {
    mayIsee() {
      return this.mayI('corporate-viewer', 'corporate-add', 'corporate-add-auth');
    }
  },
  methods: {
    edit(item) {
      this.$router.push(`/corporate/edit/${item.id}`);
    },
    pagto(to) {
      this.grids.corporates.currentPage = to;
      this.search();
    },
    search() {
      if (this.processing) return;

      this.grids.corporates.processing = true;
      this.processing = true;

      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      let search = this.form.search;

      if (search == "@me") {
        search = this.ls_get("name");
      }

      corporateService.list(search, this.grids.corporates.currentPage, this.grids.corporates.perPage, null, this.showEmpresasInativas, this.filterFilial).then(
        response => {
          this.processing = false;
          this.grids.corporates.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");

          if (this.validateJSON(response)) {
            this.grids.corporates.loaded = true;
            this.grids.corporates.items = response;
            this.grids.corporates.currentPage = response.length == 0 ? 1 : response[0]["currentPage"];
            this.grids.corporates.total = response.length == 0 ? 0 : response[0][config.system.totalCount];
          }
        },
        error => {
          this.grids.corporates.processing = false;
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          this.toastError("Falha na execução.");
        }
      );      
    }
  },
  data () {
    return {
        processing: false,
        showEmpresasInativas: false,
        filterFilial: false,
        loading: false,
        form: {
          search: '',
        },
        grids: {
            corporates: {
                processing: false,
                loaded: false,
                total: 0,
                currentPage: 1,
                perPage: 10,
                items: [],
                fields: {
                    name: { label: 'Nome da Empresa', sortable: false },
                    url: { label: 'Url de Empresa', sortable: false },
                    cnpj: { label: 'CNPJ', sortable: false },
                    status: { label: 'Status', sortable: false },
                    isfilial: { label: 'Filial', sortable: false },
                    created: { label: 'Criado em', sortable: false },
                    updated: { label: 'Atualizado em', sortable: false },
                    actions: { label: 'Ações' }
                },
            }
        }
    }
  }
}
</script>

<style>

</style>
